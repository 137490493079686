import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import Social from "../components/social"

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            message: '',
            formState: ''
        }
        this.changeEmail = this.changeEmail.bind(this);
        this.changeName = this.changeName.bind(this);
        this.changeMessage = this.changeMessage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    changeEmail(e) {
        this.setState({ email: e.target.value });
    }

    changeName(e) {
        this.setState({ name: e.target.value });
    }

    changeMessage(e) {
        this.setState({ message: e.target.value });
    }

    encode(data) {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&")
    }

    handleSubmit(e) {
        e.preventDefault();
        fetch('/', {
            method: 'POST',
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: this.encode({
                "name": this.state.name,
                "email": this.state.email,
                "message": this.state.message,
                "form-name": "contact"
            })
        }).then((data) => {
            if(data.status === 200)
                this.setState({ formState: "success" })
            else
                this.setState({ formState: "error" })
        }).catch(() =>
            this.setState({ formState: "error" })
        )
    }

    render() {
        let global = this.props.data.contentfulWebsite;
        return (
            <Layout pathname={this.props.location.pathname}>
                <Seo metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
                <div className="o-wrapper o-wrapper--xsmall o-container o-content o-content-container o-content-container--large u-text-center">
                    <h1>Contact</h1>
                    {this.state.formState === 'success' ?
                        <div>Thank you, your message has been submitted successfully.</div>
                        :
                        <form 
                            className="o-content-container" 
                            method="post" 
                            name="contact"
                            data-netlify-honeypot="bot-field" 
                            data-netlify="true" 
                            onSubmit={this.handleSubmit}>
                            {this.state.formState === 'error' ? <div>Something went wrong. Please try again later.</div> : ''}
                            <input type="hidden" name="form-name" value="contact" />
                            <input type="hidden" name="bot-field" />
                            <div>
                                <label htmlFor="contact__name">Name</label>
                                <input className="c-form__input" required id="contact__name" name="name" type="text" value={this.state.name} onChange={this.changeName}></input>
                            </div>
                            <div>
                                <label htmlFor="contact__email">Email</label>
                                <input className="c-form__input" required id="contact__email" name="email" type="email" value={this.state.email} onChange={this.changeEmail}></input>
                            </div>
                            <div>
                                <label htmlFor="contact__message">Details</label>
                                <input className="c-form__input" required id="contact__message" name="message" type="text" value={this.state.message} onChange={this.changeMessage}></input>
                            </div>
                            <button type="submit" className="c-button c-button--inline c-button--filled u-center">Send Message</button>
                        </form>
                    }
                    <a className="h4" href="mailto:socialcanada@uninterrupted.com">socialcanada@uninterrupted.com</a>
                    <Social type="body"></Social>
                </div>
            </Layout>
        );
    }
};

export default Contact

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "UNINTERRUPTED Canada"}) {
        author
        description
        title
        shareImage {
            file {
                url
            }
        }
    }
}
`;
